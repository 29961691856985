import React, { ComponentProps, useState } from 'react';
import { UnknownObjectAny } from 'global.types';
import { useRouter } from 'next/router';
import GlobalErrors from 'components/GlobalErrors';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react';
import { PRESCRIPTION_PROFILE_URL } from 'utils/constants';
import { useStore } from 'store';
import { Controller, useForm } from 'react-hook-form';
import { getPasswordViolations } from 'api/lib/form-utils';
import MaskedDateInput, { dateRegexPattern } from './inputs/MaskedDateInput';

const formConfig = {
  pwLength: '10 or more characters',
  pwUpper: 'At least 1 capital letter',
  pwNumber: 'At least 1 number',
  pwSymbol: 'At least 1 symbol character'
};

export interface UpdatePasswordFormProps {
  maxWidth?: ComponentProps<typeof Stack>['maxWidth'];
  outerProps?: Omit<ComponentProps<typeof Stack>, 'padding' | 'style'>;
  cancelOnClickHandler?: () => void;
}

const UpdatePasswordForm = (props: UpdatePasswordFormProps): JSX.Element => {
  // props
  const { maxWidth, outerProps, cancelOnClickHandler } = props;
  // state
  const { patientStore, modalsAndDrawersStore } = useStore();
  const [passwordViolations, setPasswordViolations] = useState<
    | {
      pwRequired: boolean;
      pwLength: boolean;
      pwUpper: boolean;
      pwLower: boolean;
      pwNumber: boolean;
      pwSymbol: boolean;
      pwChar: boolean;
    }
    | undefined
  >(undefined);
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { handleSubmit, control, getValues, ...useFormProperties } = useForm<UnknownObjectAny>({
    defaultValues: undefined,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  const onSubmit = async (data: UnknownObjectAny) => {
    const id = modalsAndDrawersStore?.updatePassword?.id;
    const pin = modalsAndDrawersStore?.updatePassword?.pin;

    setIsLoading(true);

    try {
      const res = await patientStore.updatePassword({
        ...data,
        id: id,
        pin: pin
      });

      if (res?.hasError) {
        setApiError(res.error);
      } else {
        setApiError(undefined);
        setSuccessMessage(
          'Your password has been updated successfully, you will be redirected in a moment.'
        );
        setIsLoading(false);

        try {
          patientStore.setAutomaticLogout(false);
          const loginResponse = await patientStore.login(id, data?.confirmPassword);

          if (loginResponse?.hasErrors) {
            setApiError(loginResponse?.errors || 'Something went wrong. Please try again.');
            setIsLoading(false);
            return;
          }

          setTimeout(() => {
            modalsAndDrawersStore?.close('updatePassword');
            router.push(PRESCRIPTION_PROFILE_URL);
          }, 2000);
        } catch (error) {
          console.error('ERROR SIGNING IN');
          console.error(error);
          setApiError('ERROR SIGNING IN: ' + error || 'Something went wrong. Please try again.');
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('ERROR UPDATING PASSWORD');
      console.error(error);
      setApiError('ERROR UPDATING PASSWORD: ' + error || 'Something went wrong. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <Stack maxWidth={maxWidth ?? '450px'} padding="small" {...outerProps}>
      <Text fontSize="1rem" mb="0.5rem">
        You signed in using a temporary password. Please update your password before continuing.
      </Text>
      {apiError && <GlobalErrors globalErrors={[apiError]} />}
      <Box as="form" id="update-password_form" onSubmit={handleSubmit(onSubmit as any)}>
        <Stack spacing="4">
          <Controller
            name="password"
            control={control}
            rules={{
              required: 'This field is required',
              validate: (val) => {
                const violations = getPasswordViolations(val, formConfig);

                const isPasswordInvalid = Object.values(violations).reduce(
                  (_val, cur) => _val || cur
                );
                setPasswordViolations(violations);
                return isPasswordInvalid ? 'Password does not meet the requirements' : undefined;
              }
            }}
            defaultValue=""
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { error, invalid }
            }) => (
              <Stack>
                <FormControl isInvalid={!!invalid}>
                  <FormLabel htmlFor={name}>Password</FormLabel>
                  <Input
                    placeholder="********"
                    type="password"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    id={name}
                  />
                  <FormErrorMessage>{error?.message}</FormErrorMessage>
                </FormControl>
                <Box pt="0.2rem">
                  <Text
                    color={passwordViolations?.pwLength ? 'error' : 'secondary'}
                    fontSize="0.875rem"
                  >
                    1. 10 or more characters
                  </Text>
                  <Text
                    color={passwordViolations?.pwUpper ? 'error' : 'secondary'}
                    fontSize="0.875rem"
                  >
                    2. At least 1 capital letter
                  </Text>
                  <Text
                    color={passwordViolations?.pwNumber ? 'error' : 'secondary'}
                    fontSize="0.875rem"
                  >
                    3. At least 1 number
                  </Text>
                  <Text
                    color={passwordViolations?.pwSymbol ? 'error' : 'secondary'}
                    fontSize="0.875rem"
                  >
                    4. At least 1 symbol character (@ ! # $ % etc)
                  </Text>
                </Box>
              </Stack>
            )}
          />

          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: 'This field is required',
              validate: (val) => {
                return val === getValues().password ? undefined : 'Passwords must match';
              }
            }}
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { error, invalid }
            }) => (
              <FormControl isInvalid={!!invalid}>
                <FormLabel htmlFor={name}>Confirm Password</FormLabel>
                <Input
                  placeholder="********"
                  type="password"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  id={name}
                />
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Controller
            name="dob"
            control={control}
            rules={{
              required: 'This field is required',
              validate: (val) => {
                const validity = val.match(dateRegexPattern)
                  ? undefined
                  : 'Please enter a valid date.';

                return validity;
              }
            }}
            render={({
              field: { onBlur, onChange, value, name },
              fieldState: { error, invalid }
            }) => (
              <FormControl isInvalid={!!invalid}>
                <FormLabel htmlFor={name}>Date of birth (MM/DD/YYYY)</FormLabel>
                <MaskedDateInput
                  label="Date of birth (MM/DD/YYYY)"
                  name={name}
                  type="datetime"
                  placeholder="MM/DD/YYYY"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  tone="critical"
                  message={error?.message}
                />
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Stack>
        {successMessage && !apiError ? (
          <Box
            paddingY="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            marginX="auto"
          >
            <Text align="center" size="semiLarge">
              {successMessage}
            </Text>
            <Box marginTop="1rem" marginX="auto">
              <Spinner color="primary.default" size="md" />
            </Box>
          </Box>
        ) : (
          <Box
            marginTop="1.5rem"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            flexDirection={{ base: 'column', md: 'row-reverse' }}
            width="100%"
          >
            <Button
              isLoading={isLoading}
              variant="ld"
              type="submit"
              width={{ base: '100%', md: '48%' }}
              mb={{ base: '1rem', md: undefined }}
            >
              Submit
            </Button>
            <Button
              width={{ base: '100%', md: '48%' }}
              onClick={() => {
                cancelOnClickHandler?.();
                modalsAndDrawersStore?.updatePasswordData({
                  id: undefined,
                  pin: undefined
                });
                router.replace('/');
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default UpdatePasswordForm;
