import { Box, Heading, Text } from '@chakra-ui/layout';
import { UnknownObjectAny } from 'global.types';
import React, { ReactElement } from 'react';
import { commonGradients, presetComponentProps } from 'theme';
import {
  HeaderOverlayBannerData,
  HeaderOverlayButtonData,
  HeaderOverlayTitlesData,
  PageCMSData
} from 'types/cms';
import HeroWave from '../../public/assets/hero-wave.svg';
import { HeaderOverlayBanner, HeaderOverlayButton, HeaderOverlayTiles } from '.';

export interface HeroProps {
  data: PageCMSData;
  children?: JSX.Element;
}

const Hero = ({ data, children }: HeroProps): ReactElement => {
  const headerOverlayContent = data?.headerOverlayContentCollection?.items;

  const cmsChildComponents: UnknownObjectAny = {
    HeaderOverlayBanner: () => (
      <HeaderOverlayBanner data={headerOverlayContent?.[0] as HeaderOverlayBannerData} />
    ),
    HeaderOverlayButton: () => (
      <HeaderOverlayButton data={headerOverlayContent as HeaderOverlayButtonData[]} />
    ),
    HeaderOverlayTiles: () => (
      <HeaderOverlayTiles data={headerOverlayContent as HeaderOverlayTitlesData[]} />
    )
  };

  const child = () => {
    const typeName: string = headerOverlayContent?.[0]?.__typename || '';
    const CmsChild = cmsChildComponents[typeName];

    if (CmsChild) return <CmsChild data={headerOverlayContent} />;

    if (children) return children;

    return null;
  };

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignContent="flexEnd"
      justifyContent="center"
      w="100%"
      mb={child() ? '3em' : 0}
      background={commonGradients?.transparentToWhiteGray}
    >
      <Box
        minH={{
          base: data?.heroImageMobile?.url ? `${+data?.heroImageMobile.height}px` : '90px',
          sm: data?.heroImageMobile?.url ? `${+data?.heroImageMobile.height + 100}px` : '100px',
          md: data?.heroImageMobile?.url ? `${+data?.heroImageMobile.height + 150}px` : '150px',
          lg: data?.heroImage?.url ? `${+data?.heroImage.height - 200}px` : '180px'
        }}
        bgPos="top center"
        bgSize="cover"
        bgRepeat="no-repeat"
        bgImage={{
          base: data?.heroImageMobile?.url ?? undefined,
          md: data?.heroImage?.url ?? undefined
        }}
        display="flex"
        flexDirection="column"
        alignContent="flexEnd"
        justifyContent="center"
        mb={{ md: '2.2em', xl: '0.875em', '2xl': '0' }}
      >
        <Box w="auto" mx={{ base: '.75rem', md: '1rem' }} pb={{ base: '2.5rem', md: '5rem' }}>
          {data?.headlineH1 && (
            <Heading
              {...presetComponentProps?.h1}
              textTransform="capitalize"
              textAlign="center"
              lineHeight={{ base: '1', md: '1.2' }}
              w="auto"
              mx="auto"
              color={{
                base: data?.heroImageMobile?.url ? 'white' : 'primary.default',
                md: data?.heroImage?.url ? 'white' : 'primary.default'
              }}
              textShadow={{
                base: data?.heroImageMobile?.url ? '0 0 6px rgba(0,0,0,0.2)' : 'none',
                md: data?.heroImage?.url ? '0 0 6px rgba(0,0,0,0.2)' : 'none'
              }}
            >
              {data?.headlineH1}
            </Heading>
          )}
          {data?.subhead && (
            <Text
              textAlign="center"
              fontSize={{ base: 'sm', md: 'xl', lg: '2xl' }}
              w="auto"
              mx="auto"
              color={{
                base: data?.heroImageMobile?.url ? 'white' : 'primary.vibrant',
                md: data?.heroImage?.url ? 'white' : 'primary.vibrant'
              }}
              textShadow={{
                base: data?.heroImageMobile?.url ? '0 0 6px rgba(0,0,0,0.2)' : 'none',
                md: data?.heroImage?.url ? '0 0 6px rgba(0,0,0,0.2)' : 'none'
              }}
            >
              {data?.subhead}
            </Text>
          )}
        </Box>
      </Box>
      <Box
        h="auto"
        w="100%"
        d="flex"
        flexDir="column"
        justifyContent="center"
        color="black"
        mt={{ base: '-2.4em', md: '-5em', '2xl': '-6em' }}
        zIndex={9}
      >
        <Box>
          <Box as={HeroWave} fill="white" h="auto" w="100%" display="block" my={0} />
          <Box
            bgColor="white"
            h={{ base: '0', md: child() ? '50px' : '30px' }}
            w="100%"
            display="block"
            my={0}
          // mt="-0.1rem"
          />
        </Box>
        <div>        <Box
          h={child() ? 'auto' : '2em'}
          w="100%"
          bgColor="white"
          pt={{ base: '1em', md: 0 }}
          mt={{ base: '-0.1rem', md: '-0.8rem', lg: '-1rem', '2xl': '-2rem' }}
        >
          {child() && (
            <Box
              h="auto"
              bgColor="transparent"
              width="100%"
              d="flex"
              justifyContent="center"
              mt={{ base: '0', md: '-5em', lg: '-4em', '2xl': '-8em' }}
              px="1em"
            >
              {child()}
            </Box>
          )}
        </Box></div>
      </Box>
    </Box>
  );
};

export default Hero;
