import React, { ReactElement, useCallback } from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { UnknownObjectAny } from 'global.types';

export interface GlobalErrorsProps {
  globalErrors: string[] | undefined;
  orderResponse?: UnknownObjectAny;
  isQuickRefill?: boolean;
}

const GlobalErrors = (props: GlobalErrorsProps): ReactElement => {
  // props
  const { globalErrors, orderResponse, isQuickRefill } = props;
  // Refs
  const alertRef = useCallback((node) => {
    if (node !== null) {
      window.scrollTo({
        behavior: 'smooth',
        top: node?.offsetTop - 200
      });
    }
  }, []);

  const uniqueGlobalErrors = globalErrors ? [...new Set(globalErrors)] : [];

  return (
    <Box marginBottom="5">
      {uniqueGlobalErrors?.length > 0 && (
        <div ref={alertRef}>
          <Stack spacing="2">
            {uniqueGlobalErrors.map((err, i) => {
              return (
                <Stack
                  key={`global-errors-${err}-${i}`}
                  backgroundColor="yellow.100"
                  border="1px solid #ffc107"
                  padding="4"
                  spacing="2"
                  fontSize={{ base: "sm", md: "md" }}
                >
                  <Flex alignItems="center">
                    <WarningTwoIcon color="#FFC107" marginRight="2" />
                    {isQuickRefill ? (
                      <>
                        {orderResponse?.OrderItems?.row?.[i]?.Rxnumber ? (
                          <Text fontWeight="bold">
                            RX# {orderResponse?.OrderItems?.row?.[i]?.Rxnumber}
                          </Text>
                        ) : (
                          <Text fontWeight="bold">Unable to process refill</Text>
                        )}
                      </>
                    ) : (
                      <Text display="inline" marginX="auto" style={{ width: 'auto !important' }}>
                        {err}
                      </Text>
                    )}
                  </Flex>
                  {isQuickRefill && <Text>{err}</Text>}
                </Stack>
              );
            })}
          </Stack>
        </div>
      )}
    </Box>
  );
};

export default GlobalErrors;
