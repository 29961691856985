const AMJAY_SUBDOMAIN = process.env.NEXT_PUBLIC_AMJAY_SUBDOMAIN;
export const RX_SCODE = process.env.NEXT_PUBLIC_AMJAY_SCODE;

export const AMJAY_API_DOMAIN = `https://${AMJAY_SUBDOMAIN}.londondrugs.com/rxapp`;
// export const AMJAY_API_PROD_DOMAIN = `https://${AMJAY_PROD_SUBDOMAIN}.londondrugs.com/rxapp`;
export const AMJAY_IMAGE_DOMAIN = `https://${AMJAY_SUBDOMAIN}.londondrugs.com/centralprofile/images/drugpics`;
// export const AMJAY_IMAGE_PROD_DOMAIN = `https://${AMJAY_PROD_SUBDOMAIN}.londondrugs.com/centralprofile/images/drugpics`;

export const LOGIN_LOCATION = '/Amjaygetlogin';
export const QUICK_REFILL_LOCATION = '/Amjayorderbyrxnum';
export const GET_PRESCRIPTION_LOCATION = '/Amjaymultigetprofile';
export const GET_PRESCRIPTION_DETAILS = '/amjaygetcounsel';
export const GET_STORE_DETAILS = '/amjaygetstoreinfo';
export const UPDATE_AUTOREFILL_STATUS = '/amjayautorefillstatus';
export const SCHEDULE_MEDICATION_REVIEW = '/amjayscheduleappointment';
export const SET_PREFERRED_CONTACT = '/amjaysetpreferredcontact';
export const VERIFY_RX_PHONE = '/Amjayverifyrxphone';
export const VERIFY_DOB_PHN = '/Amjayverifybdatephn';
export const ADD_NEW_CARD = '/amjayaddnewcard';
export const FORGOT_PASSWORD = '/amjaygetnewpassword';
export const RETRIEVE_USERID = '/amjayretrievecard';

// Password reset
export const ENCRYPT_PIN = '/amjayencrypt';
export const ENCRYPTION_KEY = '565bdeb01ed4d9f5c8e29ce96ff83f628df56d9bc3cd5cf743cb4b72c857786a';

export const ACCOUNT_TEMP_LOCKED = /account has been temporarily locked/i;
export const ACCOUNT_TEMP_LOCKED_HARDCODED =
  'Your account has been temporarily locked because of too many failed login attempts. Please try logging in after 1 hour (from when the account was locked).';
export const INVALID_CARDNAME_PASSWORD = 'Invalid card number or password, please try again.';
// Order statuses from Amjay that we classify as errors/successes
// Any status not listed here is classified as a "warning"
export const INPUT_ERROR_STATUSES = ['Failed', 'InvalidRxPhoneMatch', 'invalidrx'];
export const GENERIC_ERROR_STATUSES = [
  'inactive',
  'TooEarly',
  'NoPhoneFaxRefills',
  'OnOrder',
  'zerorepeat'
];
export const SUCCESS_STATUSES = ['ok', 'Ordered'];
export const ORDERED_STATUS = 'Ordered';
export const NETWORK_ERROR =
  'Sorry, there was a problem with your Internet connection. Please make sure it is working and try again.';
export const TECHNICAL_DIFFICULTIES_ERROR =
  'We are currently experiencing technical difficulties, please try again later or contact the pharmacy directly';

// Phrases that denote request to your physician
export const SEND_REQUEST_TO_PHYSICIAN = /send a refill request to your physician/i;
export const WILL_CONTACT_DOCTOR = /we will contact your doctor/i;
export const NOTE_PREFIX = /NOTE:/;
export const UNKNOWN_STORE_NAME = 'Unknown Store';