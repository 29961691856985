// import * as Sentry from 'sentry-expo'
import { UnknownObjectAny } from 'global.types';
import { PreferenceRequestData } from 'hooks/usePatientPreferences';
import * as constants from './constants';
import { serialize, sha256 } from './utils';

/**
 * Sample requests that return data
 * patientLogin('8900070979', 'London123$')
 * updateUserAgreement('8900070979', 'London123$')
 * updatePassword('8900070979', 'London123$', 'ABC123$123', '1980-03-05')
 * getPrescriptions('8900070979', 'London123$', 'LONBC')
 * getPrescriptionDetail('8900070979', 'London123$', 'LONBC', '2245623')
 * quickRefill({rxnums: '9000526,9000524', telephone: '6042727489', storetelephone: '6044485468', pdel: 'P', email: 'email@email.com'})
 */

export const makeAmjayRequest = async (endpoint: any, data: any) => {
  const queryParams = serialize(data);
  const domain = constants.AMJAY_API_DOMAIN;
  // process.env.NODE_ENV.trim() === 'development'
  //   ? constants.AMJAY_API_DOMAIN
  //   : constants.AMJAY_API_PROD_DOMAIN

  const url = `${domain}${endpoint}?${queryParams}`;

  try {
    const result = await fetch(url, { method: 'POST' });
    const jsonResult = await result.json();
    return jsonResult;
  } catch (error) {
    // Sentry.captureException(error)
    console.error('ERROR WITH AMJAY REQUEST');
    console.error(error);
  }
};

export const quickRefill = (quickRefillData: any) => {
  /**
   * NOTE: The telephone property isn't necessarily the one that is listed under the
   * patient's profile in the login response for some reason. It may be different,
   * it will be on the prescription list response under the patient info.
   */
  const data = {
    ...quickRefillData,
    scode: constants.RX_SCODE
  };

  return makeAmjayRequest(constants.QUICK_REFILL_LOCATION, data);
};

export const getStoreInfo = (storetelephone: any) => {
  const data = {
    storetelephone,
    scode: constants.RX_SCODE
  };

  return makeAmjayRequest(constants.GET_STORE_DETAILS, data);
};

// Returns all prescriptions under given pharmacy + patient profile
export const getPrescriptions = (id: string, pin: string, store: any) => {
  const data = {
    id,
    pin: sha256(pin),
    store,
    scode: constants.RX_SCODE,
    patid: '',
    nhid: ''
  };

  return makeAmjayRequest(constants.GET_PRESCRIPTION_LOCATION, data);
};

export const getPrescriptionDetail = async (id: string, pin: string, store: any, din: any) => {
  const data = {
    id,
    pin: sha256(pin),
    store,
    scode: constants.RX_SCODE,
    din
  };
  const prescriptionDetail = await makeAmjayRequest(constants.GET_PRESCRIPTION_DETAILS, data);
  if (prescriptionDetail?.Counselling?.row) {
    return prescriptionDetail.Counselling.row.filter((item: any) => {
      return (
        item.CategoryName !== 'Title' &&
        item.CategoryName !== 'Common BrandNames' &&
        item.CategoryName !== 'Pronunciation' &&
        item.CounselText !== ''
      );
    });
  }
  return [];
};

export const updateAutoRefillStatus = ({
  pharmacyPhone,
  patientPhone,
  rxNumber,
  autoRefillType
}: any) => {
  const data = {
    storetelephone: pharmacyPhone,
    patientelephone: patientPhone,
    rxnumber: rxNumber,
    autorefilltype: autoRefillType,
    scode: constants.RX_SCODE
  };
  return makeAmjayRequest(constants.UPDATE_AUTOREFILL_STATUS, data);
};

export const scheduleMedicationReview = ({
  firstname,
  lastName,
  pharmacyPhone,
  patientPhone,
  comments
}: any) => {
  const data = {
    firstname: firstname,
    lastname: lastName,
    storetelephone: pharmacyPhone,
    patientelephone: patientPhone,
    comments: comments,
    appointmenttype: 'MedReview',
    scode: constants.RX_SCODE
  };
  return makeAmjayRequest(constants.SCHEDULE_MEDICATION_REVIEW, data);
};

// Patient login
export const patientLogin = async (id: string, pin: string) => {
  const data = {
    id,
    pin: sha256(pin),
    agree: '',
    pinchange: '',
    newpin: '',
    scode: constants.RX_SCODE
  };

  try {
    const loginResult = await makeAmjayRequest(constants.LOGIN_LOCATION, data);
    let error = loginResult.row ? loginResult.row.row[0].Error : false;

    if (error) {
      if (constants.ACCOUNT_TEMP_LOCKED.test(error)) {
        // LP-269: Simpler message for users to understand
        // (plus, we don't need to worry about localizing the timestamp)
        error = constants.ACCOUNT_TEMP_LOCKED_HARDCODED;
      }
      if (error === 'Invalid CardNumber or Password Error') {
        error = constants.INVALID_CARDNAME_PASSWORD;
      }
      // save the login error here to display to the user
      return {
        hasErrors: true,
        errors: error
      };
    }

    const patientData: UnknownObjectAny = loginResult.Patients.row[0];
    const storeData = loginResult.Stores.row;
    const patientKey = patientData?.PatientKey;
    // Amjay might send a bad response where every field is an empty
    // string. Unfortunately, we'll have to guard against it.
    if (!patientKey) {
      // Sentry.captureMessage('Amjay empty response - patientLogin')
      return {
        hasErrors: true,
        errors: constants.TECHNICAL_DIFFICULTIES_ERROR
      };
    }

    return { patientData, storeData };
  } catch (error) {
    // Sentry.captureException(error)
    console.error('ERROR LOGGING IN');
    console.error(error);
  }
};

export const updateUserAgreement = (id: string, pin: string) => {
  const data = {
    id,
    pin: sha256(pin),
    agree: 'Agree',
    pinchange: '',
    newpin: '',
    scode: constants.RX_SCODE
  };

  return makeAmjayRequest(constants.LOGIN_LOCATION, data);
};

const encryptPin = async (pin: string) => {
  const data = {
    strtoencrypt: pin,
    pwd: constants.ENCRYPTION_KEY,
    scode: constants.RX_SCODE
  };
  try {
    const json = await makeAmjayRequest(constants.ENCRYPT_PIN, data);
    return json.Item.row[0].encrypted;
  } catch (error) {
    // Sentry.captureException(error)
    console.error('ERROR GETTING ENCRYPTED PIN');
    console.error(error);
  }
};

export const updatePassword = async (id: string, pin: string, newPin: any, dateOfBirth: any) => {
  try {
    const encryptedNewPin = await encryptPin(newPin);
    const data = {
      id,
      pin: sha256(pin),
      agree: '',
      pinchange: 'Ok',
      newpin: encryptedNewPin,
      DOB: dateOfBirth,
      scode: constants.RX_SCODE
    };
    return makeAmjayRequest(constants.LOGIN_LOCATION, data);
  } catch (error) {
    // Sentry.captureException(error)
    console.error('ERROR UPDATING PASSWORD');
    console.error(error);
  }
};

export const verifyRxAndPhone = async (vals: {
  phone: string;
  rxnumber: string;
  storetelephone: string;
}) => {
  try {
    const data = {
      ...vals,
      scode: constants.RX_SCODE
    };
    return makeAmjayRequest(constants.VERIFY_RX_PHONE, data);
  } catch (error) {
    console.error('ERROR VERIFYING RX/PHONE');
    console.error(error);
  }
};

export const retrieveUserId = async (vals: {
  firstName: string;
  lastName: string;
  dob: string;
  storetelephone: string;
}) => {
  try {
    const data = {
      ...vals,
      scode: constants.RX_SCODE
    };
    return makeAmjayRequest(constants.RETRIEVE_USERID, data);
  } catch (error) {
    console.error('ERROR RETRIEVING USER ID');
    console.error(error);
  }
};

export const verifyDobAndPhn = async (vals: {
  dob: string;
  phn: string;
  patid: string;
  storetelephone: string;
}) => {
  try {
    const data = {
      ...vals,
      scode: constants.RX_SCODE
    };
    return makeAmjayRequest(constants.VERIFY_DOB_PHN, data);
  } catch (error) {
    console.error('ERROR VERIFYING DOB/Personal Health Number');
    console.error(error);
  }
};


export const addNewCard = async (vals: {
  storetelephone: string;
  patid: string;
}) => {
  try {
    const data = {
      ...vals,
      scode: constants.RX_SCODE
    };
    return makeAmjayRequest(constants.ADD_NEW_CARD, data);
  } catch (error) {
    console.error('ERROR VERIFYING DOB/Personal Health Number');
    console.error(error);
  }
};

export const getNewPassword = async (vals: {
  storetelephone: string;
  patid: string;
}) => {
  try {
    const data = {
      ...vals,
      scode: constants.RX_SCODE
    };
    return makeAmjayRequest(constants.FORGOT_PASSWORD, data);
  } catch (error) {
    console.error('ERROR VERIFYING DOB/Personal Health Number');
    console.error(error);
  }
};


export const updateCustomerPreferences = async (
  data: PreferenceRequestData
): Promise<{ success: boolean }> => {
  const res = await makeAmjayRequest(constants.SET_PREFERRED_CONTACT, {
    ...data,
    scode: constants.RX_SCODE
  });
  if (res?.Todo?.row?.[0]?.Id > 0) {
    return { success: true };
  }
  return { success: false };
};
